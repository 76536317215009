import React, { useState, useEffect, useRef, useCallback  } from 'react';
import {APIProvider, Map, InfoWindow, MapCameraChangedEvent, AdvancedMarker, MapControl, ControlPosition } from '@vis.gl/react-google-maps';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { JumpToLocationButton } from './components/JumpToLocationButton';


import {ClusteredMarkers} from './marker_clustering/clustered-markers';

import ControlPanel from './marker_clustering/control-panel';
import {loadNailSalondata, NailSalonsGeo} from './nail_salons';

// import './style.css';
import {Feature, Point} from 'geojson';
import {InfoWindowContent} from './marker_clustering/info-window-content';

import '../../App.css';
import './Mapstyle.css';



// interface MapProps {
//   center: google.maps.LatLngLiteral;
//   zoom: number;
//   markers: google.maps.LatLngLiteral[];
//   userLocation: google.maps.LatLngLiteral | null;
// }


const MapPage: React.FC = () => {
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>({lat: 31.777380919034055, lng: 35.23548216717965});
  const [AllShops, setAllShops] = useState<NailSalonsGeo | null>(null);
  const [numClusters, setNumClusters] = useState(0);

  const api_key = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

  useEffect(() => {
    void loadNailSalondata().then(
      data => setAllShops(data));
    if (navigator.geolocation) {
      try {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
        })
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const [infowindowData, setInfowindowData] = useState<{
    anchor: google.maps.marker.AdvancedMarkerElement;
    features: Feature<Point>[];
  } | null>(null);

  const hamdleInfoWindowClose = (
    () => 
      setInfowindowData(null)
  );


  



  return (
    
      <APIProvider apiKey={api_key} version={'beta'}>
        <ResponsiveAppBar />
          <div style={{ display: 'flex', height: '100vh', width: '100vw' }}>
            {/* <div style={{ flex: '1', position: 'relative' }}> */}
              <Map
                mapId={`${process.env.REACT_APP_GOOGLE_MAP_ID}`}
                defaultCenter={userLocation}
                defaultZoom={15}
                gestureHandling={'greedy'}
                disableDefaultUI
                className={'custom-marker-clustering-map'}
                >
                {AllShops && (
                  <ClusteredMarkers
                    geojson={AllShops}
                    setNumClusters={setNumClusters}
                    setInfowindowData={setInfowindowData}
                  />
                )}

                {infowindowData && (
                  <InfoWindow
                    onClose={hamdleInfoWindowClose}
                    anchor={infowindowData.anchor}>
                    <InfoWindowContent features={infowindowData.features} />
                  </InfoWindow>
                )}
                
                
                <MapControl position={ControlPosition.RIGHT_BOTTOM}>
                  <JumpToLocationButton />
                </MapControl>
              
              </Map>
            {/* </div> */}
            {/* <div style={{ flex: '2', position: 'relative' }}>
              <ControlPanel
                numClusters={numClusters}
                numFeatures={AllShops?.features.length || 0}
                // style={{ position: 'absolute', top: 0, right: 0, padding: 10 }}
              />
            </div> */}
        </div>
      </APIProvider>
  );
};

export default MapPage;
