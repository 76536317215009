import React from 'react';
import { Card, CardMedia, Typography, Box, Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { GiTwoCoins } from "react-icons/gi";


const OvalCard = ({ title, imageUrl, price, duration }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        borderRadius: '50px',
        overflow: 'hidden',
        maxWidth: '400px',
        height: '80px',
        margin: '20px auto',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: '#f9e6fe',
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: '80px',
          height: '80px',
          objectFit: 'cover',
        }}
        image={imageUrl}
        alt={title}
      />
      <Box sx={{ display: 'flex', width: 'calc(100% - 80px)', p: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
          <Typography variant="h6" component="div" align="center">
            {title}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: 1, borderStyle: 'dotted' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', minWidth: '80px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, }}>
            <GiTwoCoins />
            <Typography sx={{ fontSize: 16 }} variant="body2">{price}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeIcon sx={{ fontSize: 16, mr: 0.5 }} />
            <Typography sx={{ fontSize: 16 }} variant="body2">{duration}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default OvalCard;