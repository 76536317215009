import { db } from "../../components/auth/firebase";
import { doc, setDoc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { getUserInfo } from "../usersDB/UsersDB";
import { ex } from "@fullcalendar/core/internal-common";


export interface ShopInfo {
    address: string;
    email: string;
    facebook: string;
    geometry: [];
    header_image: string;
    instagram: string;
    name: string;
    phone: string;
    ShopServices: Service[] | null;
    Shopweeklyschedule: [];
    ShopData: ShopSubData | null;
}

export interface ShopSubData    {
    appointments: [];
    shopOwner: string;

}

// export interface ShopServicesData {
//     services: Service[] | null;
// }

export interface Service {
    description: string;
    image: string;
    name: string;
    price: number;
    shop_id: string;
    time_length: number;
}


// // Function to store user info
export async function storeShopInfo(userId: string, userInfo: object): Promise<void> {
    try {
        await setDoc(doc(db, "users", userId), userInfo);
        console.log("User information successfully stored!");
    } catch (error) {
        console.error("Error storing user information: ", error);
    }
    }


export async function storeShopWeeklySchedule(weeklySchedule: object []): Promise<void> {
    try {
        const userData = await getUserInfo()
        await updateDoc(doc(db, "shops", userData.shopOwned), {
            shopweeklyschedule: JSON.stringify(weeklySchedule)
        });
        console.log("Shop Hours updated successfully!");
    } catch (error) {
        console.error("Error updating shop hours: ", error);
    }
}

// export async function getShopDataCollection(): Promise<ShopSubData | null> {
//     try {
//         const userData = await getUserInfo()

//         const subcollectionRef = collection(db, 'shops', userData.shopOwned, 'ShopData');
//         const subcollectionSnapshot = await getDocs(subcollectionRef);
//         const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
//         ...doc.data()
//         }));

//         if (subcollectionData.length > 0){
//             const shopSubData: ShopSubData = {
//                 appointments: subcollectionData[0].appointments,
//                 shopOwner: subcollectionData[0].shopOwner
//             }
//             return shopSubData;
//         }
//         console.error("Error getting user data collection information: ", "No data found");
//         return null;
//     } catch (error) {
//         console.error("Error getting user data collection information: ", error);
//         return null;
//     }
// }

export async function getShopServicesCollection(ShopId: string): Promise<Service[] | null> {
    try {
        const subcollectionRef = collection(db, 'shops', ShopId, 'ShopServices');
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
        ...doc.data()
        }));

        if (subcollectionData.length > 0){
            const shopSubData: Service[] = subcollectionData.map(doc => ({
                description: doc.description,
                image: doc.image,
                name: doc.name,
                price: doc.price,
                shop_id: doc.shop_id,
                time_length: doc.time_length
            }))
            return shopSubData;
        }
        console.error("No data found");
        return [];
    } catch (error) {
        console.error("Error getting shop services collection information: ", error);
        return null;
    }
}

// Function to get user info
export async function getPublicShopInfo(ShopId: string | null): Promise<ShopInfo | null> {
    try {
        if (auth.currentUser) {
            var docSnap
            if (ShopId !== null) {
                docSnap = await getDoc(doc(db, "shops", ShopId));                
            }
            else {
                const userData = await getUserInfo()
                docSnap = await getDoc(doc(db, "shops", userData.shopOwned));

            }
            return {
                address: docSnap.data().address,
                email: docSnap.data().email,
                facebook: docSnap.data().facebook,
                geometry: docSnap.data().geometry,
                header_image: docSnap.data().header_image,
                instagram: docSnap.data().instagram,
                name: docSnap.data().name,
                phone: docSnap.data().phone,
                ShopServices: docSnap.data().shopservices.map(doc => (JSON.parse(doc))),
                Shopweeklyschedule: JSON.parse(docSnap.data().shopweeklyschedule),
                ShopData: {
                    appointments: [],
                    shopOwner: ""
                }
            }
        }
        else return null;
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return null;
    }
}