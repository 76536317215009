import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from "axios";
import ResponsiveAppBar from '../../components/appbar/Appbar';
import LilacCalendar from '../../components/calendar/Calendar';
import { useParams } from 'react-router-dom';
import { db } from '../../components/auth/firebase';
import { doc, collection, getDoc } from 'firebase/firestore'; 
import { InstagramEmbed } from 'react-social-media-embed';
import { auth } from '../../components/auth/firebase';
import { Grid } from '@mui/material';
import IconRow from '../../components/iconrow/IconRow';
import ShopServices from '../../components/shopservices/ShopServices';
import { getPublicShopInfo, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import '../../App.css';

// Set the default configuration for Axios
// axios.defaults.withCredentials = true;




const ShopPage: React.FC = () => {

  
  const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const shop_id = queryParams.get('shopID');

  let navigate = useNavigate(); 

  const getShopInfo = async () => {
    // const shopsSnapshot = await getDoc(doc(db, "shops", shop_id));

    const shopData: ShopInfo = await getPublicShopInfo(shop_id)
    setShopInfo(shopData);
    }
  
  

  useEffect(() => {
    void getShopInfo();
  }, []);

  // if ((shopInfo.properties.instagram === '') || (shopInfo.properties.instagram === undefined)) {
  //   return (
  //     <div>
  //     <ResponsiveAppBar />
  //     <div className="lilac-calendar-container">
  //       <LilacCalendar shopId={shop_id}/>
  //     </div>      
  //   </div>
  //   );
  // }
  return (
    <div style={{ backgroundColor: "#fff8f5" }}>
      <ResponsiveAppBar />      
      <IconRow shopInfo={shopInfo}/>
      <ShopServices shopInfo={shopInfo}/>
      {/* <div className="lilac-calendar-container">
        <LilacCalendar shopId={shop_id}/>
      </div>       */}
    </div>
  );

};

export default ShopPage;