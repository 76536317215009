import React, { useEffect, useState, useRef } from 'react';
import { Calendar, formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import axios, { AxiosResponse } from "axios";
import SearchBox from '../searchbox/SearchBox';
import { isMobile } from 'react-device-detect';
import { createOwnerAppointment, getAppointmentInfo, deleteAppointment } from '../../resources/appointmentsDB/AppointmentsDB';
import './ShopOwnerCalendar.css'
import { getPublicShopInfo } from '../../resources/shopsDB/ShopsDB';


interface ShopOwnerCalendarProps {
  shopId: string
  DeleteAppointments: boolean;
  // Not: shopData: string
}

export interface Event {
  id: string,
  client_id: string,
  shop_id: string,
  title: string,
  start: Date,
  end: Date,
  service: string,
  allDay: boolean,
  status: string,
}

const ShopOwnerCalendar: React.FC<ShopOwnerCalendarProps> = ({shopId, DeleteAppointments}) => {
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [focusDate, setFocusDate] = useState(null);
  // const [shopId, setShopId] = useState(shopId);
  const [currentEvents, setCurrentEvents] = useState<Event[]>([]);
  const [userEvents, setUserEvents] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  
  



  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    setCurrentEvents([...userEvents,...selectedEvents])
  }, [userEvents,selectedEvents]);

  useEffect(() => {
    if (focusDate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(focusDate);
      setFocusDate(null); // Reset focus date after navigating
    }
  }, [focusDate]);

  const fetchEvents = async () => {
    await setIsLoading(true);
    const events_list = await getAppointmentInfo(shopId)
    await setCurrentEvents(events_list)
    await setIsLoading(false);
  }

  const handleEventDidMount = (info) => {
    if (info.view.type === 'dayGridMonth') {
      info.el.style.display = 'none';
    }
  };
  

  // Adds color coding to the day cells based on the availability of events
  const dayCellDidMount = (arg) => {
    const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
    if (dateNumber) {
      dateNumber.style.pointerEvents = 'none';
      dateNumber.style.cursor = 'default';
    }
    const { date, el } = arg;
    const dayEvents = currentEvents.filter(event => 
      event.start.toDateString() === date.toDateString()
    );

    if (dayEvents.length > 0) {
      const allAvailable = dayEvents.every(event => event.status == 'available');
      const someAvailable = dayEvents.some(event => event.status == 'available');

      if (allAvailable) {
        el.classList.add('fc-day-all-available');
      } else if (someAvailable) {
        el.classList.add('fc-day-some-available');
      } else {
        el.classList.add('fc-day-none-available');
      }
    }
  };


  /* handles how the calendar should behave when a date is selected
  if the view is month, change to day view
  if the hours are selected create an event and add it to the database
  */
  const handleDateSelect = async (selectInfo: any) => {
    if (selectInfo.view.type === 'dayGridMonth') {
      let calendarApi = selectInfo.view.calendar
      calendarApi.changeView('timeGridDay', selectInfo.startStr);
      return

    }
    if (selectInfo.dateStr) {
      return
    }

    const calendarEvents =  selectInfo.view.calendar.getEvents()

    
    let confirmSlot = window.confirm('Would you like to add available hours between \n' + new Date(selectInfo.startStr).toLocaleString() + '\n to \n' + new Date(selectInfo.endStr).toLocaleString())
    let calendarApi = selectInfo.view.calendar
    

    calendarApi.unselect() // clear date selection
    if (!confirmSlot) {
      return
    }

    // get difference in milliseconds
    var appointmentLength = (new Date(selectInfo.endStr).getTime() - new Date(selectInfo.startStr).getTime())
    // convert to hours
    appointmentLength = appointmentLength / 1000 / 60 / 60
    // convert to number of 30 minute blocks
    appointmentLength = appointmentLength * 2

    const shopInfo = await getPublicShopInfo(null)

    await createOwnerAppointment({
      title: shopInfo.name,
      start: new Date(selectInfo.startStr),
      end: new Date(selectInfo.endStr),
      shop_id: shopId,
      status: 'available',
    })
    await fetchEvents()
    setFocusDate(selectInfo.start);

  }



  const handleEventClick = async (clickInfo: any) => {
    let calendarApi = clickInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return
    }
    if (DeleteAppointments === false) {
      return
    }
    const removeEvent = window.confirm(`Are you sure you want to delete the event ${clickInfo.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${clickInfo.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
    if (removeEvent) {
      await deleteAppointment(clickInfo.event.id)
      await fetchEvents()
      setFocusDate(clickInfo.event.start);
    }
    
  }



  const handleEventDisplay = (event: any) => {
    return
  }


  



  const handleEvents = (events: any) => {
    // setCurrentEvents(events)
  }


  const renderEventContent = (eventInfo: any) => {
    let calendarApi = eventInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return (
        null 
      )
    }
    else{
    return (
      <>
        <b>{eventInfo.timeText}</b> 
        <br />
        <i>{eventInfo.event.title}</i>
        <br />
        <i>{eventInfo.event.extendedProps.status}</i>
        {/* <i> {eventInfo.event.shop_id}</i> */}
      </>
    )
    }
  }

  const renderSidebarEvent = (event: any) => {
    return (
      <li key={event.id}>
        <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
        <i> {event.title}</i>
      </li>
    )
  }

  /*
  //FIX this!!!

  const createAndMergeTimeSlot = useCallback((newStart, newEnd) => {
    const calendarApi = calendarRef.current.getApi();
    let slot = { start: newStart, end: newEnd };
    let slotsToDelete = [];
    let mergeOccurred = true;

    while (mergeOccurred) {
      mergeOccurred = false;
      const allEvents = calendarApi.getEvents();

      for (let existingEvent of allEvents) {
        if (canMerge(slot, existingEvent)) {
          slot = mergeSlots(slot, existingEvent);
          slotsToDelete.push(existingEvent);
          mergeOccurred = true;
          break;
        }
      }
    }

    // Create the new merged event
    calendarApi.addEvent({
      start: slot.start,
      end: slot.end,
      title: 'Merged Slot',
    });

    // Remove the old events that were merged
    slotsToDelete.forEach(event => event.remove());
  }, []);

  const canMerge = (slot, event) => {
    const slotStart = slot.start.getTime();
    const slotEnd = slot.end.getTime();
    const eventStart = event.start.getTime();
    const eventEnd = event.end.getTime();
  
    return (slotEnd >= eventStart && slotStart <= eventEnd) ||
           (eventEnd >= slotStart && eventStart <= slotEnd);
  };

  const mergeSlots = (slot, event) => {
    return {
      start: new Date(Math.min(slot.start.getTime(), event.start.getTime())),
      end: new Date(Math.max(slot.end.getTime(), event.end.getTime())),
    };
  };
  */
  

  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        
        <div className='demo-app-main'>
          <FullCalendar
          ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridDay',
            }}
            initialView='timeGridDay'
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            editable={true}
            selectable={true}
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={currentEvents}
            slotDuration={{ minutes: 30 }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ hours: 1 }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            aspectRatio={1.35}
            dayCellDidMount={dayCellDidMount}
            eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default ShopOwnerCalendar;