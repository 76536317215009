import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthContextType, UserType } from './types'; // Define types for user and context
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut,signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { app, auth } from './firebase'; // Import Firebase app
import { storeUserInfo, getUserInfo} from '../../resources/usersDB/UsersDB'; // Import function to store user info in Firestore




await setPersistence(auth, browserLocalPersistence)

export const signIn = async (email: string, password: string) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res
  } catch (error) {
    // Handle errors appropriately
    
  }
};

// Initialize Firebase Auth provider
const googleProvider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
googleProvider.setCustomParameters({   
    prompt : "select_account "
});

export const signInWithGooglePopup = async () => {
  const res = await signInWithPopup(auth, googleProvider)
  const userInfo = await getUserInfo();
  await storeUserInfo(res.user.email, userInfo);

  return res 
};
export const wrapperSignUp = async (email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const userInfo = {"email": res.user.email, "uid": res.user.uid, password: password};
    await storeUserInfo(res.user.email, userInfo);
    return res
  } catch (error) {
    console.log(error)
    // Handle errors appropriately
  }
};

export const wrapperSignOut = async () => {
  const res = await signOut(auth);
  return res
};

