import { db } from "../../components/auth/firebase";
import { collection, query, where, doc, setDoc, getDoc, getDocs, deleteDoc, serverTimestamp  } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { Event } from "../../components/calendar/Calendar";
import { v4 as uuidv4 } from 'uuid';

// Function to store user info
export async function createOwnerAppointment(appointmentInfo: object): Promise<void> {
    try {
        const appointmentId = uuidv4()
        appointmentInfo = {...appointmentInfo, createdAt: serverTimestamp()}
        await setDoc(doc(db, "appointments", appointmentId), appointmentInfo);
        console.log("Appointment successfully stored!");
    } catch (error) {
        console.error("Error storing appointment: ", error);
    }
    }

export async function updateAppointmentStatus(appointmentId: string, status: string): Promise<void> {}

export async function deleteAppointment(appointmentId: string): Promise<void> {
    try {
        await deleteDoc(doc(db, "appointments", appointmentId));
        console.log("Appointment successfully deleted!");
    } catch (error) {
        console.error("Error deleting appointment: ", error);
    }
}

// Function to get user info
export async function getAppointmentInfo(shop_id): Promise<Event[]> {
    try {
    const q = query(collection(db, "appointments"), where("shop_id", "==", shop_id));
    const querySnapshot = await getDocs(q);

    const appointmentList: Event[] = [];
    querySnapshot.forEach((doc) => {
        appointmentList.push(
            {
                id: doc.id,
                title: doc.data().title,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                shop_id: doc.data().shop_id,
                client_id: doc.data().client_id,
                service: doc.data().service,
                status: doc.data().status,
            } as Event
        )
    });
    return appointmentList;

    } catch (error) {
        console.error("Error getting user information: ", error);
        return [];
    }
}