import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import OvalCard from './OvalCard';
import { ShopInfo } from '../../resources/shopsDB/ShopsDB';


  interface ShopInfoComponentProps {
    shopInfo: ShopInfo;
  }

const ShopServices: React.FC<ShopInfoComponentProps> = ({shopInfo}) => {
  if (shopInfo === null) {
    return (
      <div>
      </div>
    );
  }
  return (
    <Grid container spacing={2} flexDirection={'row'} justifyContent={'center'}>
      {/* {[lightTheme, darkTheme].map((theme, index) => ( */}
        <Grid item xs={12} >
          {/* <ThemeProvider theme={theme}> */}
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                // borderRadius: 2,
                // bgcolor: 'background.default',
                background: "#fff8f5",
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
              {shopInfo.ShopServices.map((service, idx) => (
                <OvalCard key={idx} title={service.name} imageUrl={service.image} price={service.price} duration={service.time_length} />
              ))}
              </div>
            </Box>
          {/* </ThemeProvider> */}
        </Grid>
      {/* ))} */}
    </Grid>
  );
}

export default ShopServices;